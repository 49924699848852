import { useRouter } from "next/navigation";
import { Formik, FormikHelpers } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import { AxiosError } from "axios";

interface ILoginParams {
  username: string;
  password: string;
  submit: boolean;
}

const INITIAL_VALUES = {
  username: "",
  password: "",
  submit: false,
};

export default function LoginScreen() {
  const router = useRouter();

  const handleSubmit = async (
    values: ILoginParams,
    actions: FormikHelpers<ILoginParams>
  ) => {
    try {
      const { data, status, ...rest } = await fetch("/api/login", {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((data) => data.json());

      if (!data) {
        throw { ...rest, status };
      }

      router.refresh();
    } catch (error) {
      console.info("handleSubmit error:", error);

      actions.setStatus({ success: false });
      actions.setSubmitting(false);

      actions.setErrors({
        submit: (error as AxiosError)?.message ?? "Something went wrong",
      });
    }
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <>
          <Form onSubmit={handleSubmit} className="d-flex flex-column gap-1">
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>

              <Form.Control
                size="lg"
                type="text"
                name="username"
                placeholder="Enter your username"
                value={values.username}
                isInvalid={Boolean(touched.username && errors.username)}
                onChange={handleChange}
                required
              />

              {!!touched.username && (
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>

              <Form.Control
                size="lg"
                type="password"
                name="password"
                placeholder="Enter your password"
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onChange={handleChange}
                required
              />

              {!!touched.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <div className="d-grid gap-2 mt-3">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                Sign in
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
