"use client";

import { Card } from "react-bootstrap";

import LoginScreen from "@app/pages/Login/Login";

export default function Login() {
  return (
    <>
      <div className="text-center mt-4">
        <h2>Welcome To Cabin Select</h2>

        <p className="lead">Sign in to your account to continue</p>
      </div>

      <Card>
        <Card.Body style={{ overflow: "unset" }}>
          <div className="m-sm-3">
            <LoginScreen />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
